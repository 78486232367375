const useSpecialWheelDecor = () => {
	const startDate = new Date(Date.UTC(2024, 9, 21, 4, 0, 0));
	const endDate = new Date(Date.UTC(2024, 9, 25, 4, 0, 0));

	const { durationExceeded: exceededStart } = useCountdown({ timestamp: startDate.toString(), isOptimized: true });
	const { durationExceeded: exceededEnd } = useCountdown({ timestamp: endDate.toString(), isOptimized: true });

	const isSpecialWheel = computed(() => exceededStart.value && !exceededEnd.value);

	return {
		isSpecialWheel
	};
};

export default useSpecialWheelDecor;
